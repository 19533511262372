/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 1700 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "Nahidul",
  title: "Hi all, I'm K.M. NAHIDUL ISLAM",
  subTitle: emoji(
    "I'm a web Designer with extensive experience.My expertise is to create and website design,graphic design, and many more..."
  ),
  resumeLink:
    "https://cdn.kmnahidulislam.com/cv.pdf", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/kmnahidulislam",
  linkedin: "https://www.linkedin.com/in/kmnahidulislambd/",
  gmail: "kmnahidulislambd@gmail.com",
  gitlab: "https://gitlab.com/kmnahidulislam",
  facebook: "https://www.facebook.com/kmnahidulislambd",
  whatsapp: "https://wa.me/+8801303918392",
  instagram: "https://www.instagram.com/kmnahidulislambd",
  medium: "https://medium.com/@kmnahidulislam",
  stackoverflow: "https://stackoverflow.com/users/22344185/kmnahidulislam",
  // Instagram, Twitter and Kaggle are also supported in the links!
  // To customize icons and social links, tweak src/components/SocialMedia
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "CRAZY FULL STACK DEVELOPER WHO WANTS TO EXPLORE EVERY TECH STACK",
  skills: [
    emoji(
      "⚡ Develop highly interactive User Interfaces for your web applications"
    ),
    emoji("⚡ Progressive Web Applications ( PWA ) in normal and SPA Stacks"),
    emoji(
      "⚡ Integration of third party services such as Firebase/ AWS"
    )
  ],


  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "sass",
      fontAwesomeClassname: "fab fa-sass"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "swift",
      fontAwesomeClassname: "fab fa-swift"
    },
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm"
    },
    {
      skillName: "sql-database",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "aws",
      fontAwesomeClassname: "fab fa-aws"
    },
    {
      skillName: "firebase",
      fontAwesomeClassname: "fas fa-fire"
    },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "fab fa-docker"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "Sufalakati Secondary School",
      logo: require("./assets/images/harvardLogo.png"),
      subHeader: "Seconary School Certificate (SSC)",
      duration: "January 2018 - March 2023",
      desc: "I got GPA : 5.00",
    },
    {
      schoolName: "Govt. Sundarban Adarsha College",
      logo: require("./assets/images/college.png"),
      subHeader: "Higher Secondary Certificate (HSC)",
      duration: "October 2023 - unknown",
      desc: "result: pending",
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: " Web Development", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Web Design",
      progressPercentage: "80%"
    },
    {
      Stack: "Programming",
      progressPercentage: "60%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: false, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Unknown",
      company: "Unknown",
      companylogo: require("./assets/images/empty1.png"),
      date: "Unknown – Unknown",
      desc: "Nothing is here but I will add my new work experience here .",
      descBullets: [
        "Empty",
        "Empty"
      ]
    },
    {
      role: "Unknown",
      company: "Unknown",
      companylogo: require("./assets/images/empty2.png"),
      date: "Unknown – Unknown",
      desc: "Nothing is here but I will add my new work experience here."
    },
    {
      role: "Unknown",
      company: "Unknown",
      companylogo: require("./assets/images/empty3.png"),
      date: "Unknown – Unknown",
      desc: "Nothing is here but I will add my new work experience here."
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: false // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Big Projects",
  subtitle: "SOME STARTUPS AND COMPANIES THAT I HELPED TO CREATE THEIR TECH",
  projects: [
    {
      image: require("./assets/images/empty1.png"),
      projectName: "Unknown",
      projectDesc: "Empty",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://www.kmnahidulislam.com/"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/empty2.png"),
      projectName: "Unknown",
      projectDesc: "Empty",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://www.kmnahidulislam.com/"
        }
      ]
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("About My wishes "),
  subtitle:
    "",

  achievementsCards: [

    {
      title: "Dream",
      subtitle:
        "My dream to become a BUETian",
      image: require("./assets/images/buet.png"),
      imageAlt: "BUET logo",
      footerLink: [
        {name: "Nahidul", 
         url: "https://www.kmnahidulislam.com/"}
      ]
    },
    
    {
      title: "Unknown",
      subtitle: "Nothing is here but I will add my new acheivement here",
      image: require("./assets/images/empty3.png"),
      imageAlt: "Unknown Logo",
      footerLink: [
        {name: "website", 
         url: "https://www.kmnahidulislam.com/"}
      ]
    }
  ],
  display: false // Set false to hide this section, defaults to true
};


// Blogs Section

const blogSection = {
  title: "Blogs",
  subtitle:
    "With Love for Developing cool stuff, I love to write and teach others what I have learnt.",
  displayMediumBlogs: "true", // Set true to display fetched medium blogs instead of hardcoded ones
  blogs: [
    {
      url: "https://blog.kmnahidulislam.com",
      title: "Unknown",
      description:
        "Nothing is here but I will add my new blog here"
    },
    {
      url: "https://blog.kmnahidulislam.com/",
      title: "Unknown",
      description:
        "Nothing is here but I will add my new blog here."
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE "
  ),

  talks: [
    {
      title: "my talks(Unknown)",
      subtitle: "Nothing is here but something special will be added here",
      slides_url: "https://www.kmnahidulislam.com/",
      event_url: "https://www.kmnahidulislam.com/"
    }
  ],
  display: false // Set false to hide this section, defaults to true
};
// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://www.kmnahidulislam.com/404.html"
  ],
  display: false // Set false to hide this section, defaults to true
};
const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Discuss a project or just want to say hi? My Inbox is open for all.",
  number: "+8801303918392",
  email_address: "kmnahidulislambd@gmail.com"
};

// Twitter Section

const twitterDetails = {
  userName: "geniusnahidul", //Replace "twitter" with your twitter username without @
  display: false // Set true to display this section, defaults to false
};

const isHireable = false; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
  isHireable
};
